<template>
	<div class="animated fadeIn">
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							v-if="admin.includes(this.$user.details().type)"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('user_id')">-->
							{{ translate('created_by') }}
							<!--<sort field="user_id" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('coupon_code')">-->
							{{ translate('coupon_code') }}
							<!--<sort field="coupon_code" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('coupon_type')">-->
							{{ translate('coupon_type') }}
							<!--<sort field="coupon_type" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('coupon_type')">-->
							{{ translate('discount_rate') }}
							<!--<sort field="coupon_type" />-->
						</th>
						<th
							v-if="ALLOW_PERMANENT_COUPONS_IN_COUNTRIES.includes(country)"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('is_permanent')">-->
							{{ translate('is_permanent') }}
							<!--<sort field="is_permanent" />-->
						</th>
						<th
							v-if="[statuses.expired, statuses.active].includes(status)"
							class="border-top-0 pointer"
							@click="sortByField('expiration_date')">
							{{ translate(status === statuses.expired ? 'expired_at' : 'expires_at') }}
							<sort field="expiration_date" />
						</th>
						<th
							v-if="status === statuses.used"
							class="border-top-0 pointer"
							@click="sortByField('used_date')">
							{{ translate('used_date') }}
							<sort field="used_date" />
						</th>
						<th
							v-if="status === statuses.used"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('used_by_id')">-->
							{{ translate('used_by') }}
							<!--<sort field="used_by_id" />-->
						</th>
						<th
							v-if="status === statuses.active"
							class="border-top-0 text-center">
							{{ translate('options') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td
							v-if="admin.includes($user.details().type)"
							class="align-middle">
							<div class="row no-gutters flex-nowrap">
								<div
									style="width: 60px;"
									class="col-auto">
									<span
										class="badge badge-primary pointer"
										@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">
										{{ item.attributes.user_id }}
									</span>
								</div>
								<div class="pl-2 col">
									{{ item.attributes.username }}
								</div>
							</div>
						</td>
						<td class="align-middle">
							{{ item.attributes.coupon_code }}
						</td>
						<td class="align-middle">
							{{ translate(`${item.attributes.coupon_type}_type`) }}
						</td>
						<td class="align-middle text-center">
							{{ item.attributes.discount_rate }}
						</td>
						<td
							v-if="ALLOW_PERMANENT_COUPONS_IN_COUNTRIES.includes(country)"
							class="align-middle text-center">
							<i
								v-if="item.attributes.is_permanent"
								class="fas fa-lg fa-check-circle" />
						</td>
						<td
							v-if="[statuses.expired, statuses.active].includes(status)"
							class="align-middle">
							{{ item.attributes.expiration_date.date ? $moment(item.attributes.expiration_date.date).format(dateFormat) : $moment(item.attributes.expiration_date).format(dateFormat) }}
						</td>
						<td
							v-if="status === statuses.used"
							class="align-middle">
							{{ item.attributes.used_date.date ? $moment(item.attributes.used_date.date).format(dateFormat) : $moment(item.attributes.used_date).format(dateFormat) }}
						</td>
						<td
							v-if="status === statuses.used"
							class="align-middle">
							<div class="row no-gutters flex-nowrap">
								<div
									style="width: 60px;"
									class="col-auto">
									<span class="badge badge-primary">
										{{ item.attributes.used_by_id }}
									</span>
								</div>
								<div class="pl-2 col">
									{{ item.attributes.used_by_username }}
								</div>
							</div>
						</td>
						<td
							v-if="status === statuses.active"
							class="align-middle text-center">
							<b-button
								v-clipboard="() => item.attributes.coupon_code"
								v-b-tooltip.hover
								:title="translate('copy_code')"
								class="btn mx-1 bg-primary-alt"
								@click="copy()">
								<i class="fas fa-copy" />
							</b-button>
							<b-button
								v-if="item.attributes.can_be_deleted && $can('coupons', 'delete')"
								v-b-tooltip.hover
								:title="translate('delete')"
								class="btn mx-1 bg-primary-alt"
								@click="deleteCoupon(item.id)">
								<i class="fas fa-trash-alt" />
							</b-button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import { PAGINATION } from '@/settings/RequestReply';
import { Grids, Coupons as CouponMessages } from '@/translations';
import { YMDHMS_FORMAT } from '@/settings/Dates';
import { ALLOW_PERMANENT_COUPONS_IN_COUNTRIES, COUPON_STATUSES } from '@/settings/Coupons';
import { admin } from '@/settings/Roles';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import Coupons from '@/util/Coupons';

export default {
	name: 'CouponList',
	messages: [Grids, CouponMessages],
	mixins: [FiltersParams, DashboardRedirect],
	props: {
		status: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			coupons: new Coupons(),
			removeCoupon: new Coupons(),
			alert: new this.$Alert(),
			dateFormat: YMDHMS_FORMAT,
			statuses: COUPON_STATUSES,
			admin,
			ALLOW_PERMANENT_COUPONS_IN_COUNTRIES,
		};
	},
	computed: {
		loading() {
			return !!this.coupons.data.loading;
		},
		pagination() {
			return this.coupons.data.pagination;
		},
		data() {
			try {
				const { data } = this.coupons.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
	},
	mounted() {
		this.$route.query.status = this.status;
		this.coupons.getCoupons();
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}

			const { query } = this.$route;
			const options = { ...query, ...this.filters, status: this.status };

			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			this.coupons.getCoupons(options);
		},
		copy() {
			this.alert.toast('success', this.translate('coupon_code_copied'));
		},
		deleteCoupon(id) {
			const couponCode = this.data.find((coupon) => +coupon.id === +id).attributes.coupon_code;
			const trans = {
				title: this.translate('delete_coupon'),
				text: this.translate('delete_coupon_message', { couponCode }),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
			};
			this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
				this.removeCoupon.deleteCoupon(id).then(() => {
					try {
						this.alert.toast('success', this.translate('coupon_deleted'));
					} finally {
						this.coupons.getCoupons();
					}
				});
			}).catch(() => {});
		},
	},
};
</script>
